// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink]
})
export class FooterComponent implements OnInit {
  year!: number;

  constructor() {}

  ngOnInit(): void {
    let date = new Date();
    this.year = date.getFullYear();
  }

  goToExternal(url: string): void {
    window.open(url, '_blank');
  }
}
