// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Component, OnInit } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, tap } from 'rxjs/operators';
import { AnnouncementComponent } from './components/announcement/announcement.component';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [MenuComponent, FooterComponent, RouterModule],
  providers: [DialogService]
})
export class AppComponent implements OnInit {
  public primeNgModalRef!: DynamicDialogRef;

  constructor(private dialogService: DialogService, private router: Router) {
    this.handleRouteWithHash();
  }

  ngOnInit() {
    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        // if(item.url === "/") {
        //   this.openAnnouncementModal();
        // }
        // this.openAnnouncementModal();
      }
    });
  }

  private handleRouteWithHash() {
    this.router.events
      .pipe(
        filter((event): event is NavigationStart => event instanceof NavigationStart),
        filter(event => !!event.url && !!event.url.match(/^\/#/)),
        tap(event => this.router.navigateByUrl(event.url.replace('/#', '')))
      )
      .subscribe();
  }

  openAnnouncementModal() {
    const modalOptions: DynamicDialogConfig = {
      showHeader: false,
      closeOnEscape: true
    };

    this.primeNgModalRef = this.dialogService.open(AnnouncementComponent, modalOptions);
  }
}
