// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
export class EnvConfig {
  constructor(ec: EnvConfig) {
    this.production = ec.production;
    /** BackEnd Urls */
    this.backendBaseUrl = ec.backendBaseUrl;
    this.apiCorePathUrl = ec.apiCorePathUrl;
    /** FrontEnd Urls */
    this.clientPortalUrl = ec.clientPortalUrl;
    this.clinicianPortalUrl = ec.clinicianPortalUrl;
    /** Other */
  }
  readonly production: boolean;
  /** BackEnd Urls */
  readonly backendBaseUrl: string;
  readonly apiCorePathUrl: string;
  get apiCoreBaseUrl(): string {
    return `${this.backendBaseUrl}/${this.apiCorePathUrl}`;
  }
  /** FrontEnd Urls */
  readonly clientPortalUrl: string;
  readonly clinicianPortalUrl: string;
  /** Other */
}
