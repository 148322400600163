// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  standalone: true,
  imports: [ButtonModule]
})
export class AnnouncementComponent {
  constructor(private primeNgModalRef: DynamicDialogRef) {}

  handleDismissButtonClick(): void {
    this.primeNgModalRef.close();
  }
}
