<!--Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.-->
<div class="modal-content">
  <div class="modal-header">
    <!-- <button type="button"
            class="close pull-right"
            (click)="handleDismissButtonClick()">       
      <span><i class="fas fa-times"></i></span>
    </button> -->
    <button pButton type="button" (click)="handleDismissButtonClick()"> <span><i
          class="pi pi-times"></i></span></button>
    <h4 class="modal-title w-100">COVID-19 Updates</h4>
  </div>
  <div class="modal-body">
    <p>
      Now providing temperature screenings. Email
      <a href="mailto:hello@sadianthealth.com">hello@sadianthealth.com</a> to discuss how Sadiant can help.
    </p>
    <div class="sadiant-logo"></div>
  </div>
</div>
